// =============================================================================
// :: Logo
// =============================================================================
.a-logo {
	position: relative;

	width: 16rem;
}

// =============================================================================
// :: Elements - content
// =============================================================================
.a-logo__content {
	width: 100%;
	max-width: 100%;
}
