// =============================================================================
// :: Banner
// =============================================================================
.o-banner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 35rem;
	margin: 0 auto 8rem;

	text-align: center;

	background-color: $background-color-grey-lightest;

	@include respond-to(get-breakpoint("viewport-7")) {
		height: 30rem;
		margin-bottom: 6rem;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.o-banner__content {
	margin: 0;
}

.o-banner__title {
	margin-bottom: 2rem;

	font-family: $font-family-beausans-regular;
	font-weight: $font-weight-bold;
}

// =============================================================================
// :: Modifiers
// =============================================================================
.o-banner--white {
	background-color: $background-color-white;
}

.o-banner--small {
	height: 30rem;

	.o-banner__title {
		margin-bottom: 1.5rem;
	}
}

.o-banner--no-margin {
	margin: 0;
}
