// =============================================================================
// :: Settings
// =============================================================================
$item-width: 2.2rem;
$item-height: 0.2rem;
$item-spacing: 0.2rem;

$item-width-short: 1.8rem;

// =============================================================================
// :: Hamburger
// =============================================================================

// =============================================================================
// :: Elements - Button
// =============================================================================
.a-hamburger__button {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	max-width: 2.3rem;
	padding: 0;

	background-color: transparent;
	border: none;

	transition: transform 0.3s ease-in-out;

	&:hover {
		cursor: pointer;
	}
}

// =============================================================================
// :: Elements - Svg
// =============================================================================
.a-hamburger__svg {
	transition: transform 0.4s;

	-webkit-tap-highlight-color: transparent;

	user-select: none;

	cursor: pointer;
}

.a-hamburger__svg-line {
	fill: none;

	stroke: $secondary-color;

	stroke-width: 5.5;
	stroke-linecap: round;

	transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s;
}

.a-hamburger__svg-top {
	stroke-dasharray: 40 139;
}

.a-hamburger__svg-bottom {
	stroke-dasharray: 40 180;
}

// =============================================================================
// :: Elements - Item
// =============================================================================
.a-hamburger__item {
	display: block;
	width: $item-width;
	height: $item-height;
	margin: $item-spacing 0;

	background-color: $secondary-color;

	transform-origin: center;

	transition: transform 0.8s ease-in-out 0.3s;
}

// =============================================================================
// :: Modifiers - item short
// =============================================================================
.a-hamburger__item--short {
	width: $item-width-short;

	transform-origin: center right;

	transition: transform 0.3s ease-in-out 0.9s;
}

// =============================================================================
// :: States - closed
// =============================================================================

// =============================================================================
// :: States - closed - svg
// =============================================================================
.a-hamburger {

	&.is-close {

		.a-hamburger__svg {
			transform: rotate(45deg);

			.a-hamburger__svg-top {
				stroke-dashoffset: -98px;
			}

			.a-hamburger__svg-bottom {
				stroke-dashoffset: -138px;
			}
		}
	}
}
