// =============================================================================
// :: Settings
// =============================================================================
$chapter-gutter-mobile: 1rem;
$chapter-gutter: 2rem;

$grid-col-1: 100% / 12;
$grid-col-2: 100% / 6;
$grid-col-3: 100% / 4;
$grid-col-4: 33.3%;
$grid-col-5: 100% / 12 * 5;
$grid-col-6: 49.9%;
$grid-col-7: 100% / 12 * 7;
$grid-col-8: 66.5%;
$grid-col-9: 100% / 12 * 9;
$grid-col-10: 100% / 12 * 10;
$grid-col-11: 100% / 12 * 11;
$grid-col-12: 100%;

// ============================================================================
// :: Chapter Overview
// ============================================================================
// .m-chapter-overview {}

// =============================================================================
// :: Elements
// =============================================================================
.m-chapter-overview__title {
	font-size: $font-size-5x-large;
	line-height: $line-height-3x-large;
}

.m-chapter-overview__sub-title {
	display: block;

	font-size: $font-size-3x-large;
}

.m-chapter-overview__list {
	width: calc(100% + #{$chapter-gutter-mobile});
	margin: calc((#{$chapter-gutter-mobile} * -0.5));

	@include respond-at(get-breakpoint("viewport-7")) {
		width: calc(100% + #{$chapter-gutter});
		margin: calc((#{$chapter-gutter} * -0.5));
	}

	> [class*="l-grid__col"] {
		margin: calc((#{$chapter-gutter-mobile} * 0.5));

		@include respond-at(get-breakpoint("viewport-7")) {
			margin: #{$chapter-gutter * 0.5};
		}
	}

	> .l-grid__col--4 {
		width: calc(#{$grid-col-12} - #{$chapter-gutter-mobile});

		@include respond-at(get-breakpoint("viewport-4")) {
			width: calc(#{$grid-col-12} - #{$chapter-gutter-mobile});
		}

		@include respond-at(get-breakpoint("viewport-7")) {
			width: calc(#{$grid-col-6} - #{$chapter-gutter});
		}

		@include respond-at(get-breakpoint("viewport-10")) {
			width: calc(#{$grid-col-4} - #{$chapter-gutter});
		}
	}
}
