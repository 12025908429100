// =============================================================================
// :: Contact
// =============================================================================
.m-contact {
	position: relative;

	display: block;
	padding: 2rem 4rem;

	background-color: $background-color-white;
	box-shadow: $box-shadow-large;

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		height: 100%;

		border-left-width: 1rem;
		border-left-style: solid;
		border-image: linear-gradient(to bottom, #A9CB38, #4AAD52) 1 100%;

		transform-origin: left center;

		transition: transform 0.3s ease-in-out;
	}

	&:hover {
		cursor: pointer;

		&::before {
			transform: scaleX(1.3);
		}
	}
}

.m-contact--linked-in {
	display: flex;
	justify-content: left;
	align-items: center;
	padding: 0.8rem 4rem;

	.IN-widget {
		margin-left: 2rem;
	}
}
