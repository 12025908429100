// ============================================================================
// :: Colors
// ============================================================================
$green: #A9C23F;
$green-dark: #8FAD15;

$dark-brown: #4E4B48;
$brown: #968C83;

$black: #1A1A1A;
$white: #FFFFFF;

$grey-dark: #B5AEA9;
$grey-light: #D5D1CE;
$grey-extra-light: #EAE8E6;
$grey-lightest: #F4F3F3;

$orange: #FF6A39;

// ============================================================================
// :: Contextual colors
// ============================================================================
// primary
$primary-color: $green;

// secondaries
$secondary-color: $green-dark;

// UI colors
$ui-color-warning: $orange;

// text
$text-color-dark-brown: $dark-brown;
$text-color: $brown;
$text-color-white: $white;
$text-color-grey: $grey-dark;
$text-color-grey-light: $grey-light;
$text-color-grey-extra-light: $grey-extra-light;
$text-color-primary: $primary-color;
$text-error: $ui-color-warning;

// borders
$border-color: rgba($dark-brown, 0.1);
$border-color-brown: $brown;
$border-color-primary: $primary-color;
$border-color-grey: $grey-light;
$border-color-light-grey: $grey-extra-light;

// background-colors
$background-color-white: $white;
$background-color-black: $black;
$background-color-extra-light: $grey-extra-light;
$background-color-grey-lightest: $grey-lightest;
$background-color-primary: $primary-color;
$background-color-brown: $brown;
$background-color-dark-brown: $dark-brown;
$background-color-light-brown: rgba($brown, 0.3);

// ============================================================================
// :: Gradients
// ============================================================================
$gradient-primary: linear-gradient(90deg, $secondary-color 0%, $primary-color 100%);

// ============================================================================
// :: Shadows
// ============================================================================
$box-shadow-1x-large: 0 0 6.2rem -1.1rem rgba(0, 0, 0, 0.2);
$box-shadow-large: 0 0 6.2rem -1.1rem rgba(0, 0, 0, 0.1);
$box-shadow: 0 0 1rem 0 rgba(15, 13, 9, 0.25);
$box-shadow-light: 0 0 4rem 0 rgba(0, 0, 0, 0.1);
