// ============================================================================
// :: List icon
// ============================================================================
// Extendable styling for the green arrow before a list item

@mixin extend-list-icon-arrow {
	margin-top: -0.2rem;
	margin-right: 1rem;

	font-size: $font-size-2x-large;
	line-height: inherit;

	color: $text-color-primary;
}
