// =============================================================================
// :: Overlay
// =============================================================================
.s-overlay {

	h4 {
		@include extend-h3-base();

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	h5 {
		@include extend-h4-base();

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	.m-trivia__title {
		color: $text-color-white;
	}

	.m-compliance__header {
		color: $text-color-white;
	}
}
