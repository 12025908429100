// =============================================================================
// :: LANGUAGE
// =============================================================================
.m-language {
	position: relative;

	overflow: visible;
}

// =============================================================================
// :: ELEMENTS
// =============================================================================
.m-language__content {
	position: absolute;

	margin-top: 1rem;
	padding: 0 1.5rem;

	background-color: $background-color-white;
	border-radius: $border-radius-small;
}

.m-language__list {

	.is-active {
		color: $text-color-primary;
	}
}

.m-language__header {
	padding-right: 3rem;
}
