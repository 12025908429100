// ============================================================================
// :: Link
// ============================================================================
@mixin extend-link {
	position: relative;

	font-size: $font-size-1x-large;
	line-height: $line-height;
	font-weight: $font-weight-semi;
	text-decoration: none;
	word-wrap: break-word;
	hyphens: auto;

	color: inherit;

	transition: color 0.2s ease-in-out;

	&:hover {
		color: $text-color-primary;

		cursor: pointer;
	}
}

// ============================================================================
// :: Link with hover animation
// ============================================================================
@mixin extend-link-inverted {
	color: $text-color-white;

	&:hover {
		color: $text-color-white;
	}

	&::before {
		color: $text-color-white;
	}
}

@mixin extend-link-animated {
	position: relative;

	&::after {
		content: "";

		position: absolute;
		bottom: 0;
		left: 0;

		display: block;
		width: 100%;
		height: 0.1rem;

		background-color: $background-color-light-brown;

		transform-origin: center right;

		transition: background-color 0.2s ease-in-out;
	}

	&:hover {

		&::after {
			background-color: $background-color-primary;

			animation: line 1.5s cubic-bezier(0.4, 0, 0.2, 1);
		}
	}
}

@mixin extend-link-animated-inverted {

	&::after {
		background-color: $background-color-white;
	}

	&:hover {
		color: $text-color-white;

		&::after {
			background-color: $background-color-white;

			animation: lineInverted 1.5s cubic-bezier(0.4, 0, 0.2, 1);
		}
	}
}

// =============================================================================
// :: Animation
// =============================================================================
@keyframes line {

	0% {
		background-color: $background-color-brown;

		transform-origin: center right;

		transform: scaleX(1);
	}

	25% {
		background-color: $background-color-brown;

		transform-origin: center right;

		transform: scaleX(0);
	}

	35% {
		background-color: $background-color-primary;

		transform-origin: center left;

		transform: scaleX(0);
	}

	50% {
		background-color: $background-color-primary;

		transform: scaleX(1);

		transform-origin: center left;
	}
}

@keyframes lineInverted {

	0% {
		background-color: $background-color-white;

		transform-origin: center right;

		transform: scaleX(1);
	}

	25% {
		background-color: $background-color-white;

		transform-origin: center right;

		transform: scaleX(0);
	}

	35% {
		background-color: $background-color-white;

		transform-origin: center left;

		transform: scaleX(0);
	}

	50% {
		background-color: $background-color-white;

		transform: scaleX(1);

		transform-origin: center left;
	}
}
