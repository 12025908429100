// =============================================================================
// :: Actions
// =============================================================================
.m-actions {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;

	text-align: center;
}

// =============================================================================
// :: Elements
// =============================================================================
.m-actions__item {

	&:not(:last-child) {
		margin-right: 2rem;
	}
}

.m-actions__option {
	margin: 0.2rem 0.5rem;
}
