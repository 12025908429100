// =============================================================================
// :: Compliance
// =============================================================================
.m-compliance {
	padding: 8rem 0;

	color: $text-color-white;
	background-color: $background-color-primary;
}

// =============================================================================
// :: Elements
// =============================================================================
.m-compliance__header {
	margin-bottom: 2rem;

	color: $text-color-white;
}

.m-compliance__content {
	color: $text-color-white;

	& strong {
		color: $text-color-white;
	}
}

.m-compliance__list {

	.m-list__item {
		margin: 0;

		span {
			margin-right: 1rem;
		}
	}
}

.m-compliance__footer {
	margin-top: 5rem;
}
