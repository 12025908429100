// =============================================================================
// :: Summary
// =============================================================================
.m-summary {
	background-color: $background-color-grey-lightest;

	.l-container {

		& > .l-grid:first-child {

			@include respond-to(get-breakpoint("viewport-10")) {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			& > .m-summary__content {
				position: relative;

				padding-left: 4.5rem;

				&::before {
					content: "";

					position: absolute;
					top: 0;
					left: 0;

					display: block;
					width: 0.2rem;
					height: 100%;

					background: linear-gradient($primary-color, $secondary-color);
				}

				@include respond-at(get-breakpoint("viewport-4")) {
					padding-left: 5rem;
				}
			}

			& > .m-summary__content ~ .m-summary__content {

				&::before {
					content: "";

					display: none;
				}

				@include respond-at(get-breakpoint("viewport-4")) {
					padding-left: 0;
				}
			}
		}
	}
}

// =============================================================================
// :: Elements - intro
// =============================================================================
.m-summary__intro {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding-bottom: 5rem;

	@include respond-at(get-breakpoint("viewport-10")) {
		padding-bottom: 0;
	}

	.m-summary__intro__text {
		width: 100%;
	}
}

// =============================================================================
// :: Elements - visual
// =============================================================================
.m-summary__intro__visual {
	width: 10rem;
	margin-bottom: 2rem;

	fill: $background-color-primary;
}
// =============================================================================
// :: Elements - content
// =============================================================================
.m-summary__content-item {

	@include respond-at(get-breakpoint("viewport-7")) {
		padding-left: 2rem;
	}
}
