// ===========================================================================
// :: Split
// ===========================================================================
/**
 * The ebooks layout is split up into two equal columns. The split layout
 * component makes sure the content is shown correctly. It makes it easier
 * to manage spacing and edge cases.
 *
 * It uses the same principles as the layout grid. But by using it as a
 * separate component we have more control on how it is used without messing
 * up the layout grid.
 */

// =============================================================================
// :: Settings
// =============================================================================
$split-gutter: $container-spacing !default;
$split-gutter-row: $split-gutter !default;

$split-gutter-mobile: $container-spacing-mobile !default;
$split-gutter-mobile-row: $split-gutter-mobile !default;

$split-item: 49.9%;

// =============================================================================
// :: Root
// =============================================================================
.l-split {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
	width: calc(100% + #{$split-gutter-mobile});
	margin: 0 calc((#{$split-gutter-mobile} * -0.5));

	@include respond-at(get-breakpoint("viewport-7")) {
		width: calc(100% + #{$split-gutter});
		margin: 0 calc((#{$split-gutter} * -0.5));
	}
}

// =============================================================================
// :: Elements - row
// =============================================================================
.l-split__row {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

// =============================================================================
// :: Elements - container
// =============================================================================
.l-split__container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

// =============================================================================
// :: Elements - item/header
// =============================================================================
.l-split__item,
.l-split__header {
	align-self: auto;
	flex-grow: 0;
	flex-shrink: 0;
	width: calc(100% - #{$split-gutter-mobile});
	margin: 0 #{$split-gutter-mobile-row * 0.5};

	@include respond-at(get-breakpoint("viewport-7")) {
		width: calc(100% - #{$split-gutter});
		margin: 0 #{$split-gutter-row * 0.5};
	}

	@include respond-at(get-breakpoint("viewport-10")) {
		width: calc(#{$split-item} - #{$split-gutter});
	}
}

// =============================================================================
// :: Modifiers
// =============================================================================
.l-split__item--mobile-spacing {
	@include respond-to(get-breakpoint("viewport-10")) {
		margin-top: 2rem;
	}
}

.l-split--right {
	justify-content: flex-end;
}
