// =============================================================================
// :: Hero
// =============================================================================
.o-hero {
	display: flex;

	.o-hero__icon-cta {
		display: flex;
		align-items: center;

		&::before {
			font-size: $font-size-4x-large;

			color: $primary-color;
		}
	}

	@include respond-at(get-breakpoint("viewport-7")) {
		height: 100vh;
	}

	@include respond-at(get-breakpoint("viewport-10")) {
		align-items: center;
	}
}

// =============================================================================
// :: Modifiers
// =============================================================================
.o-hero--background-image {

	&::after {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-repeat: no-repeat;
		background-origin: center;
		background-position: bottom right;
		background-size: contain;
		opacity: 0.3;

		@include respond-at(get-breakpoint("viewport-10")) {
			opacity: 1;
		}
	}
}

.o-hero--ebook-2 {

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		z-index: $z-index-lowest;

		width: 100%;
		height: 100%;

		background-color: $background-color-primary;
	}

	&::after {
		z-index: $z-index-lowest;

		background-image: url(../../../assets/images/hero/ebooks-2-hero-bg.png);
	}
}

.o-hero--ebook-3 {
	background-image: linear-gradient($background-color-white 80%, transparent), url(../../../assets/images/hero/ebooks-3-hero-bg.jpg);
	background-repeat: no-repeat;
	background-position: bottom center;
	background-size: 100% auto;

	@include respond-to(get-breakpoint("viewport-10")) {
		padding-bottom: 20rem;
	}

	@include respond-at(get-breakpoint("viewport-7")) {
		background-image: linear-gradient($background-color-white 40%, transparent), url(../../../assets/images/hero/ebooks-3-hero-bg.jpg);
		background-position: bottom right;
		background-size: auto 80%;
	}

	@include respond-at(get-breakpoint("viewport-10")) {
		background-image: linear-gradient(to right, transparent, $background-color-white 60%), url(../../../assets/images/ebook-3/ebooks-3-hero-bg.jpg);
		background-position: top left, bottom left -55rem;
		background-size: auto 100%;
	}
}

.o-hero--inverted {

	& * {
		color: $text-color-white;
	}

	.a-link {

		&:hover {
			color: $text-color-white;
		}
	}

	.o-hero__icon-cta {

		&::before {
			color: $text-color-white;
		}
	}
}

.o-hero--centered {
	align-items: center;
}
