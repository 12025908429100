// =============================================================================
// :: Article highlight
// =============================================================================
.m-article-highlight {
	position: relative;

	margin: 6rem 0;

	&::before {

		@include respond-at(get-breakpoint("viewport-7")) {
			content: "";

			position: absolute;
			top: 0;
			left: -2rem;

			display: block;
			width: 0.2rem;
			height: 100%;

			background: linear-gradient($primary-color, $secondary-color);
		}

		@include respond-at(get-breakpoint("viewport-10")) {
			left: -4rem;
		}
	}
}

// =============================================================================
// :: Elements - Title
// =============================================================================
.m-article-highlight__title {
	margin-bottom: 2rem;
}
