// =============================================================================
// :: Comparison table
// =============================================================================
.m-comparison-table {
	position: relative;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	border-spacing: 0;

	@include respond-at(get-breakpoint("viewport-7")) {
		display: table;
		width: 100%;

		border-spacing: 1rem 0;
		border-collapse: separate;
	}
}

// =============================================================================
// :: Elements
// ===========================================================================
.m-comparison-table__container {
	max-width: 110rem;
	margin: 0 auto;
}

.m-comparison-table__title {
	margin: 5rem 0 2rem;

	text-align: center;
	font-size: $font-size-4x-large;
	line-height: $line-height-2x-large;
}

.m-comparison-table__subtitle {
	display: block;
	padding-bottom: 1rem;

	font-size: $font-size-1x-large;
	line-height: $line-height-2x-large;

	@include respond-at(get-breakpoint("viewport-7")) {
		display: inline;
		padding-bottom: 0;
	}
}

.m-comparison-table {
	width: 100%;
}


.m-comparison-table__head {
	border: none;
	background-color: $background-color-white;

	hyphens: auto;
}

.m-comparison-table__row {
	margin: 0 auto;

	& .m-comparison-table__error {
		color: $text-error;
	}

	& .m-comparison-table__succes {
		color: $text-color-primary;
	}

	@include respond-at(get-breakpoint("viewport-7")) {
		max-width: 100rem;
	}
}

.m-comparison-table__row-head {
	padding: 0.5rem 2rem;

	font-weight: $font-weight-semi;
	text-align: right;

	border-bottom: $border-grey;

	@include respond-to(get-breakpoint("viewport-7")) {
		width: 100%;
		min-width: 18rem;
		max-width: 30%;
		margin-right: 0;
		padding: 0.5rem 1rem 0.5rem 0;
	}

	@include respond-to(get-breakpoint("viewport-4")) {
		max-width: 50%;
	}
}

.m-comparison-table__data {
	border-bottom: $border-grey;

	@include respond-to(get-breakpoint("viewport-7")) {
		display: none;

		&.is-open {
			display: table-cell;
			width: 100%;
		}
	}
}

.m-comparison-table__col {
	position: relative;

	min-width: 12rem;
	max-width: 15rem;
	margin: 3rem;
	padding: 0.5rem 2rem;

	font-size: $font-size;
	text-align: center;

	background-color: $background-color-white;

	&::before {
		display: none;

		@include respond-at(get-breakpoint("viewport-7")) {
			content: "";

			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;

			display: table-cell;
			width: 100%;
			height: 100%;

			box-shadow: $box-shadow;
			opacity: 0.5;
		}
	}

	@include respond-at(get-breakpoint("viewport-10")) {
		min-width: 16rem;
		max-width: 16rem;
	}

	@include respond-to(get-breakpoint("viewport-7")) {
		width: 100%;
		min-width: 25rem;
		max-width: 45%;
		text-align: left;

		// flex: 1 1 35%;
		// margin: 0;

		&::before {
			display: none;
		}
	}

	@include respond-to(get-breakpoint("viewport-4")) {
		min-width: 15rem;
		max-width: 20rem;
		margin: 0;
		padding: 0 1rem;
	}
}

.m-comparison-table__row:last-of-type:not(:only-child) {

	.m-comparison-table__data,
	.m-comparison-table__row-head {
		padding-bottom: 2rem;

		border-bottom: 0;
	}
}

.m-comparison-table__header {

	@include respond-to(get-breakpoint("viewport-7")) {

		.m-comparison-table__col {
			min-width: 0;
			max-width: 20rem;
			padding: 0 0 3rem;

			hyphens: none;
		}

		.m-comparison-table__row-head--empty {
			display: none;
		}
	}

	@include respond-to(get-breakpoint("viewport-4")) {

		.m-comparison-table__row {
			margin-bottom: 1.5rem;
		}

		.m-comparison-table__header-data {
			font-size: $font-size-2x-small;
			line-height: 1.4rem;

			margin: 0 0.2rem 0 0;
			// flex-wrap: wrap;
		}
	}
}

.m-comparison-table__header-data {
	margin: 0 0.2rem 0 0;
	padding: 0.5rem 1rem;

	font-size: $font-size-2x-small;
	line-height: 1.4rem;

	border-radius: $border-radius;
	border: 0.2rem solid $border-color-grey;

	cursor: pointer;

	@include respond-at(get-breakpoint("viewport-4")) {
		margin: 0 0.5rem;

		font-size: $font-size;
		line-height: $line-height-1x-large;
	}

	@include respond-at(get-breakpoint("viewport-7")) {
		margin: 0;
		padding-top: 3rem;

		font-size: $font-size-3x-large;
		text-transform: capitalize;

		color: $text-color-dark-brown;
		background-color: $background-color-white;
		border: none;

		pointer-events: none;
		cursor: auto;
	}

	@include respond-to(get-breakpoint("viewport-7")) {

		&.checked {
			background-color: $background-color-primary;
			border-color: $green;
			color: white;
		}
	}
}

.m-comparison-table__tooltip {
	font-size: $font-size-2x-small;
	text-decoration: none;

	color: inherit;
}

// =============================================================================
// :: Modifiers
// ===========================================================================
.m-comparison-table__row-head--empty {
	border: none;
}
