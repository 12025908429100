// =============================================================================
// :: Reading progress
// =============================================================================
.o-reading-progress {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;

	display: none;
	width: 100%;
	height: 0.5rem;

	color: $primary-color;
	background-color: $background-color-light-brown;

	pointer-events: none;
}

// =============================================================================
// :: Elements
// =============================================================================
.o-reading-progress::-webkit-progress-bar {
	background-color: transparent;
}

.o-reading-progress::-webkit-progress-value {
	background-color: currentColor;
}

.o-reading-progress::-moz-progress-bar {
	background-color: currentColor;
}

.o-reading-progress__fallback {
	// fallback for browsers not supporting the <progress> element
	position: absolute;
	top: 0;
	left: 0;

	height: 100%;

	background-color: currentColor;
}

// =============================================================================
// :: Modifiers
// =============================================================================
.o-reading-progress {

	&.is-active { // if JS = enabled && content is scrollable
		display: block;
	}
}
