/* stylelint-disable selector-no-qualifying-type */

// =============================================================================
// :: LAYOUT
// =============================================================================
* {
	box-sizing: border-box;
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-size: 100%;
}

body {
	-webkit-overflow-scrolling: touch;

	color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
body {
	margin: 0;
}

body {
	transition: opacity 1s ease-in-out;

	&.has-nav-open,
	&.has-overlay-open {
		overflow: hidden;
	}

	&.no-print {

		main,
		.o-header,
		.o-footer,
		.o-sources {
			display: none;
		}

		.m-print-message {
			display: flex;
		}
	}

	@media print {

		main,
		.o-header,
		.o-footer,
		.o-sources {
			display: none;
		}
	}
}
