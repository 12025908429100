// =============================================================================
// :: Elements - Nav
// =============================================================================
.m-toc {
	padding: 0;

	list-style: none;
}

// =============================================================================
// :: Elements - Item
// =============================================================================
.m-toc__item {
	display: block;
}

// =============================================================================
// :: Elements - Nav link
// =============================================================================
.m-toc__link {
	position: relative;

	display: block;
	width: 100%;
	padding: 3rem 2rem;

	text-decoration: none;

	transform: scale(0.99, 0.99);

	transition:
		opacity 0.3s ease-in-out,
		transform 0.3s ease-in-out,
		color 0.3s ease-in-out;

	&::before {
		margin-right: 1.2rem;

		color: $text-color-primary;
	}

	&::after {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		z-index: $z-index-lowest;

		width: 100%;
		height: 100%;

		box-shadow: $box-shadow-1x-large;
		opacity: 0;

		transition: opacity 0.3s ease-in-out;
	}
}

// =============================================================================
// :: Modifiers - Indenting
// =============================================================================

// This class is used to indent a list with bullets or icons
// that extend the container of the parent.
.m-toc--indent-out {

	@include respond-at(get-breakpoint("viewport-10")) {
		margin-left: -4.3rem;
	}
}

// =============================================================================
// :: States - active
// =============================================================================
.m-toc__item {

	&.is-active,
	&:hover {

		.m-toc__link {
			transform: scale(1);

			&::after {
				opacity: 1;
			}
		}
	}
}
