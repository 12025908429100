/* stylelint-disable */

// =============================================================================
// :: ICON CLASS NAMES
// =============================================================================
// Created with the 'gulp iconfont'-task, the source-template
// of this file can be found in fonts/_icon-sources/_template.scss


// =============================================================================
// :: arrow-down
// =============================================================================
// inheritance
%__icon-arrow-down {
  &:before {
    @extend %___default-icon;
    content: "\EA01";
    font-family: "icons";
  };
}

// class definition
.icon-arrow-down {
  @extend %__icon-arrow-down;
}

// =============================================================================
// :: arrow-left
// =============================================================================
// inheritance
%__icon-arrow-left {
  &:before {
    @extend %___default-icon;
    content: "\EA02";
    font-family: "icons";
  };
}

// class definition
.icon-arrow-left {
  @extend %__icon-arrow-left;
}

// =============================================================================
// :: arrow-right
// =============================================================================
// inheritance
%__icon-arrow-right {
  &:before {
    @extend %___default-icon;
    content: "\EA03";
    font-family: "icons";
  };
}

// class definition
.icon-arrow-right {
  @extend %__icon-arrow-right;
}

// =============================================================================
// :: book
// =============================================================================
// inheritance
%__icon-book {
  &:before {
    @extend %___default-icon;
    content: "\EA04";
    font-family: "icons";
  };
}

// class definition
.icon-book {
  @extend %__icon-book;
}

// =============================================================================
// :: check-mark
// =============================================================================
// inheritance
%__icon-check-mark {
  &:before {
    @extend %___default-icon;
    content: "\EA05";
    font-family: "icons";
  };
}

// class definition
.icon-check-mark {
  @extend %__icon-check-mark;
}

// =============================================================================
// :: cross
// =============================================================================
// inheritance
%__icon-cross {
  &:before {
    @extend %___default-icon;
    content: "\EA06";
    font-family: "icons";
  };
}

// class definition
.icon-cross {
  @extend %__icon-cross;
}

// =============================================================================
// :: download
// =============================================================================
// inheritance
%__icon-download {
  &:before {
    @extend %___default-icon;
    content: "\EA07";
    font-family: "icons";
  };
}

// class definition
.icon-download {
  @extend %__icon-download;
}

// =============================================================================
// :: eyeglass
// =============================================================================
// inheritance
%__icon-eyeglass {
  &:before {
    @extend %___default-icon;
    content: "\EA08";
    font-family: "icons";
  };
}

// class definition
.icon-eyeglass {
  @extend %__icon-eyeglass;
}

// =============================================================================
// :: facebook
// =============================================================================
// inheritance
%__icon-facebook {
  &:before {
    @extend %___default-icon;
    content: "\EA09";
    font-family: "icons";
  };
}

// class definition
.icon-facebook {
  @extend %__icon-facebook;
}

// =============================================================================
// :: instagram
// =============================================================================
// inheritance
%__icon-instagram {
  &:before {
    @extend %___default-icon;
    content: "\EA0A";
    font-family: "icons";
  };
}

// class definition
.icon-instagram {
  @extend %__icon-instagram;
}

// =============================================================================
// :: linkedin
// =============================================================================
// inheritance
%__icon-linkedin {
  &:before {
    @extend %___default-icon;
    content: "\EA0B";
    font-family: "icons";
  };
}

// class definition
.icon-linkedin {
  @extend %__icon-linkedin;
}

// =============================================================================
// :: quote
// =============================================================================
// inheritance
%__icon-quote {
  &:before {
    @extend %___default-icon;
    content: "\EA0C";
    font-family: "icons";
  };
}

// class definition
.icon-quote {
  @extend %__icon-quote;
}

// =============================================================================
// :: sort-down
// =============================================================================
// inheritance
%__icon-sort-down {
  &:before {
    @extend %___default-icon;
    content: "\EA0D";
    font-family: "icons";
  };
}

// class definition
.icon-sort-down {
  @extend %__icon-sort-down;
}

// =============================================================================
// :: twitter
// =============================================================================
// inheritance
%__icon-twitter {
  &:before {
    @extend %___default-icon;
    content: "\EA0E";
    font-family: "icons";
  };
}

// class definition
.icon-twitter {
  @extend %__icon-twitter;
}

// =============================================================================
// :: youtube
// =============================================================================
// inheritance
%__icon-youtube {
  &:before {
    @extend %___default-icon;
    content: "\EA0F";
    font-family: "icons";
  };
}

// class definition
.icon-youtube {
  @extend %__icon-youtube;
}
