// =============================================================================
// :: Container
// =============================================================================
.l-container {
	width: calc(100% - (#{get-viewport-spacing("viewport-3")} * 2));
	max-width: $container-width-large;
	margin: 0 auto;

	@each $breakpoint, $params in trim-to($breakpoints, "viewport-4") {
		$viewport-spacing: map-get($params, viewport-spacing);

		@include respond-at(get-breakpoint($breakpoint)) {
			width: calc(100% - (#{$viewport-spacing} * 2));
		}
	}
}

// =============================================================================
// :: Modifiers
// =============================================================================
.l-container--small {
	max-width: $container-width-small;
}

.l-container--medium {
	max-width: $container-width-base;
}

// =============================================================================
// :: Responsive suffixes
// =============================================================================
.l-container\@below-base {

	@each $breakpoint, $params in trim-until($breakpoints-reversed, "viewport-10") {
		$viewport-spacing: map-get($params, viewport-spacing);

		@include respond-to(get-breakpoint($breakpoint)) {
			width: calc(100% - (#{$viewport-spacing * 2}));
			margin: 0 auto;
		}
	}
}
