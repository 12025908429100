// =============================================================================
// :: Settings
// =============================================================================
$visual-width: 80px;

// =============================================================================
// :: Column
// =============================================================================
.m-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include respond-at(get-breakpoint("viewport-7")) {
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

// =============================================================================
// :: Elements - content
// =============================================================================
.m-column__content {
	width: 100%;

	.m-quote__item {

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}
