// =============================================================================
// :: Card
// =============================================================================
.m-card {
	box-shadow: $box-shadow-light;
}

// =============================================================================
// :: Elements
// =============================================================================
.m-card__content {
	padding: 4rem;
}

.m-card__title {
	margin-bottom: 2rem;
}

.m-card__content-item {
	margin-bottom: 3rem;
}

.m-card__content-list {
	padding-left: 0;
}

.m-card__content-list-item {
	margin-left: 1.5rem;
}

.m-card__image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 100%;
	height: 22rem;

	background-color: $background-color-primary;
}

.m-card__image {
	width: auto;
	height: 12rem;
}

// =============================================================================
// :: Modifiers
// =============================================================================
.m-card--background-image {

	.m-card__image-container {
		height: 30rem;
	}

	.m-card__image {
		width: 100%;
		height: 100%;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
}

.m-card--border-bottom {
	position: relative;

	display: block;

	&::before {
		content: "";

		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 1rem;

		background-color: $background-color-primary;
	}
}
