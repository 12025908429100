// =============================================================================
// :: Collapse
// =============================================================================
.m-collapse {
	position: relative;

	overflow: hidden;

	padding: 3rem 3.5rem;

	background-color: $background-color-white;
	box-shadow: $box-shadow-1x-large;

	cursor: pointer;

	@include respond-at(get-breakpoint("viewport-4")) {
		padding: 3rem 5rem;
	}

	&::before {
		content: " ";

		position: absolute;
		top: 0;
		left: 0;

		height: 100%;

		border-left-width: 1rem;
		border-left-style: solid;
		border-image: $gradient-primary 1 100%;

		transform: translateX(-100%);

		transition: transform 0.3s ease-in-out;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.m-collapse__header {
	@extend %__icon-eyeglass;

	position: relative;

	display: flex;
	justify-content: flex-start;
	align-items: center;

	width: 100%;
	margin: 0;
	padding: 0;

	text-align: left;

	border: none;
	background-color: transparent;

	.m-collapse__title {
		max-width: 80%;

		font-size: $font-size;
		font-family: $font-family-beausans-light;
		line-height: $line-height-1x-large;

		color: $text-color-dark-brown;
	}

	&::before {
		position: absolute;
		top: 50%;
		right: -1rem;

		color: $text-color;
		font-size: 3.2rem;

		transform: translate(-50%, -50%);
		transform-origin: center center;

		transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
	}
}

.m-collapse__content {
	height: 0;
	overflow: hidden;

	transition: height 0.3s ease-in-out;

	.m-collapse__content-container {
		overflow: hidden;
	}

	.m-collapse {
		overflow: hidden;

		&:nth-child(n+5) {
			height: 0;
			padding: 0;

			transition: height 0.3s ease-in-out;
		}
	}
}
// =============================================================================
// :: Modifiers
// =============================================================================
.m-collapse--language {
	padding: 0;

	@include respond-at(get-breakpoint("viewport-4")) {
		padding: 0;
	}

	background-color: none;
	box-shadow: none;

	&::before {
		content: " ";

		display: none;
	}

	.m-collapse__header {
		@extend %__icon-sort-down;

		padding-right: 1.7rem;

		.m-collapse__title {
			color: $text-color-dark-brown;
			font-size: $font-size-1x-large;
		}

		&::before {
			position: absolute;
			top: 50%;

			color: $text-color;
			font-size: 1.2rem;
		}
	}
}

// =============================================================================
// :: STATES
// =============================================================================
.m-collapse {

	&.is-open {

		&::before {
			transform: translateX(0);
		}

		.m-collapse__header {

			&::before {
				color: $text-color-primary;

				transform: translate(-50%, -50%) rotate(180deg);
			}
		}

		.m-collapse__content {
			height: auto;
		}
	}

	&.is-resizing {

		.m-collapse__content {
			transition: none;
		}
	}
}
