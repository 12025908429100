// ============================================================================
// :: Settings
// ============================================================================
$grid-gutter: $container-spacing !default;
$grid-gutter-mobile: $container-spacing-mobile !default;

$grid-col-1: 100% / 12;
$grid-col-2: 100% / 6;
$grid-col-3: 100% / 4;
$grid-col-4: 33.3%;
$grid-col-5: 100% / 12 * 5;
$grid-col-6: 49.9%;
$grid-col-7: 100% / 12 * 7;
$grid-col-8: 66.5%;
$grid-col-9: 100% / 12 * 9;
$grid-col-10: 100% / 12 * 10;
$grid-col-11: 100% / 12 * 11;
$grid-col-12: 100%;

// ============================================================================
// :: Books
// =============================================================================
.m-books {

	& .m-books__item {

		&:not(:last-of-type) {
			margin-bottom: 4rem;
		}

		@include respond-at(get-breakpoint("viewport-4")) {
			width: calc(#{$grid-col-12} - #{$grid-gutter-mobile});
		}

		@include respond-at(get-breakpoint("viewport-7")) {
			width: calc(#{$grid-col-6} - #{$grid-gutter});
		}

		@include respond-at(get-breakpoint("viewport-10")) {
			width: calc(#{$grid-col-4} - #{$grid-gutter});
		}
	}
}
