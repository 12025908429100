// =============================================================================
// :: Image
// =============================================================================
.a-image {
	position: relative;

	width: 100%;
}

// =============================================================================
// :: Elements - visual
// =============================================================================
.a-image__visual {
	width: 100%;
	max-width: 100%;
}
