// =============================================================================
// :: Settings
// =============================================================================
$visual-width: 8rem;

// =============================================================================
// :: Quote
// =============================================================================

// =============================================================================
// :: Elements
// =============================================================================
.m-quote__title {
	@include extend-h4-base();

	display: block;
}

.m-quote__visual {
	width: $visual-width;
	margin: 0 0 3rem;

	@include respond-at(get-breakpoint("viewport-7")) {
		margin: 0.5rem 4rem 0 0;
	}
}

.m-quote__item {
	color: $text-color-white;
	font-style: italic;
}

.m-quote__author {
	font-style: normal;
}

// =============================================================================
// :: Modifiers - color
// =============================================================================
.m-quote--inverted {

	.m-quote__item {
		color: $text-color;
	}
}
