// =============================================================================
// :: Input
// =============================================================================
.a-input {
	position: relative;
}

// =============================================================================
// :: Elements
// =============================================================================
.a-input__container {
	position: relative;
}

%__input__field {
	position: relative;

	width: 100%;
	height: 100%;
	max-height: 4.8rem;
	overflow: hidden;
	padding: 2.8rem 3rem 1.2rem;

	font-family: $font-family-roboto;
	font-weight: $font-weight-thin;
	font-size: $font-size;
	line-height: 2.4rem;

	background-color: $background-color-extra-light;
	border: 0.1rem solid transparent;
	border-radius: 3.2rem;
	outline: none;
	color: #575552;

	appearance: none;

	transition: border 0.2s ease-in-out, padding 0.2s ease-in-out, background-color 0.2s ease-in-out;

	&:focus {
		border: $border-primary;
	}
}

.a-input__field {
	@extend %__input__field;
}

.a-input__label {
	position: absolute;
	top: 50%;
	left: 3rem;

	display: block;
	max-width: calc(100% - 10rem);
	overflow: hidden;

	font-family: $font-family-roboto;
	font-size: $font-size-2x-small;
	font-weight: $font-weight-bold;

	text-transform: uppercase;
	text-overflow: ellipsis;
	white-space: nowrap;

	color: $grey-dark;

	transform: translateY(-50%) translateY(-1.2rem);

	pointer-events: none;
}

// =============================================================================
// :: Modifiers
// =============================================================================
.a-input--select {
	@extend %__icon-arrow-down;

	.a-input__field {
		padding: 1.7rem 3rem 0.6rem;

		&:not(:placeholder-shown) {
			background-color: $background-color-extra-light;
			border: 0.1rem solid transparent;
		}
	}

	&::before {
		position: absolute;
		top: 50%;
		right: 3rem;
		z-index: $z-index-high;

		color: #575552;

		transform: translateY(-50%);
		transform-origin: center;
	}
}

// =============================================================================
// :: Animations
// =============================================================================
@keyframes show {

	0% {
		opacity: 0;
	}

	25% {
		opacity: 0;

		transform: translateY(-50%) translateY(-1.2rem);
	}

	30% {
		opacity: 0;
	}

	58% {
		transform: translateY(-50%) translateY(-1.2rem);
	}

	65% {
		opacity: 1;
	}
}

