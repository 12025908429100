// =============================================================================
// :: Print Message
// =============================================================================
.m-print-massage {
	display: none;
	justify-content: center;
	align-items: center;

	width: 100vw;
	height: 100vh;

	@media print {
		display: flex;
	}
}
