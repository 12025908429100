// =============================================================================
// :: Trivia Block
// =============================================================================
.m-trivia-block {
	position: relative;

	display: block;
	padding: 3rem 5rem;

	background-color: $background-color-white;
	box-shadow: $box-shadow-1x-large;

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		height: 100%;

		border-left-width: 1rem;
		border-left-style: solid;
		border-image: linear-gradient(to bottom, #A9CB38, #4AAD52) 1 100%;

		transform-origin: left center;

		transition: transform 0.3s ease-in-out;
	}

	&:hover {

		&::before {
			transform: scaleX(1.3);
		}
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.m-trivia-block__title {
	margin-bottom: 1.5rem;

	font-weight: $font-weight-bold;
}
