// =============================================================================
// :: z-index
// =============================================================================
$z-index-highest: 10000;
$z-index-higher: 1000;
$z-index-high: 100;
$z-index-base: 10;
$z-index-low: 1;
$z-index-lower: 0;
$z-index-lowest: -1;

// =============================================================================
// :: Container
// =============================================================================
$container-spacing-mobile: 3rem;
$container-spacing: 8rem;

$container-width-small: 60rem;
$container-width-small-breakpoint: $container-width-small + $container-spacing;

$container-width-base: 120rem;
$container-width-base-breakpoint: $container-width-base + $container-spacing;

$container-width-large: 140rem;
$container-width-large-breakpoint: $container-width-large + $container-spacing;

// =============================================================================
// :: Border
// =============================================================================
$border-primary: 1px solid $border-color-primary;
$border-primary-medium: 2px solid $border-color-primary;
$border-grey: 0.1rem solid $border-color-grey;
$border-grey-large: 0.3rem solid $border-color-light-grey;

$border-radius-small: 2rem;
$border-radius: 10rem;
$border-radius-round: 50%;
