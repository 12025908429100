// =============================================================================
// :: Chapter
// =============================================================================
.l-chapter {
	padding: 4rem 0;

	&:first-of-type {
		padding-top: 0;
	}

	&:last-of-type {
		padding-bottom: 0;
	}
}

// =============================================================================
// :: Modifiers
// =============================================================================
.l-chapter--spacing-1x-large {
	padding: 8rem 0;

	&:first-of-type {
		padding-top: 8rem;
	}
}
