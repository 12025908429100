// =============================================================================
// :: Answer
// =============================================================================
.a-answer {
	font-size: $font-size-4x-large;
	font-weight: $font-weight-normal;
}

// =============================================================================
// :: Modifiers - checked
// =============================================================================
.a-answer--checked {
	margin-right: -2rem;
	padding: 2rem;

	background-image: url("/assets/images/footer/circle.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}
