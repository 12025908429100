/* stylelint-disable */
// ============================================================================
// :: Icon inheritance
// ============================================================================
%___default-icon {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	vertical-align: middle;
	line-height: 1;
	speak: none;

	// Improve font-rendering
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}
