// =============================================================================
// :: Settings
// =============================================================================
$indent-primary-padding: calc((100vw - #{$container-width-base}) * 0.5);

// =============================================================================
// :: Indent
// =============================================================================
.l-indent-left {

	@each $breakpoint, $params in trim-to($breakpoints, "viewport-4") {
		$viewport-spacing: map-get($params, viewport-spacing);

		@include respond-at(get-breakpoint($breakpoint)) {
			padding-left: #{$viewport-spacing};
		}
	}

	@include respond-at(set-breakpoint($container-width-base)) {
		padding-left: $indent-primary-padding;
	}
}

// =============================================================================
// :: Responsive suffixes
// =============================================================================
.l-indent-left\@above-base {

	@each $breakpoint, $params in trim-to($breakpoints, "viewport-10") {
		$viewport-spacing: map-get($params, viewport-spacing);

		@include respond-at(get-breakpoint($breakpoint)) {
			padding-left: #{$viewport-spacing};
		}
	}

	@include respond-at(set-breakpoint($container-width-base)) {
		padding-left: $indent-primary-padding;
	}
}
