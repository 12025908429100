// =============================================================================
// :: Link
// =============================================================================
.a-link {
	@include extend-link();

	&[class*="icon"] {
		display: inline-flex;
		align-items: center;
	}

	&::before {
		display: inline-flex;
		margin-right: 1rem;

		color: $text-color-primary;

		transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.32, 1.27);
	}
}

// =============================================================================
// :: Modifiers
// =============================================================================
.a-link--icon-after {
	order: 1;

	&::before {
		order: 2;
		margin-right: 0;
		margin-left: 1rem;
	}
}

.a-link--move-right {
	transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.32, 1.27) 0.1s;

	&:hover {
		transform: translateX(0.6rem);

		&::before {
			transform: translateX(0.3rem);
		}
	}
}

.a-link--move-left {
	transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.32, 1.27) 0.1s;

	&:hover {
		transform: translateX(0);

		&::before {
			transform: translateX(0.3rem);
		}
	}
}

.a-link--small {
	font-size: $font-size;
	line-height: $line-height;
}

.a-link--animated {
	@include extend-link-animated();
}

.a-link--underlined {

	span:not([class*="icon-"]) {
		border-bottom: $border-primary-medium;
	}
}

.a-icon--before {
	display: flex;
	align-items: center;


	&::before {
		padding-right: 1rem;

		font-size: $font-size-4x-large;

		background: $gradient-primary;
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.a-link--green {
	color: $text-color-primary;
}

.a-link--size-s {
	font-size: $font-size;
	line-height: $line-height-1x-large;

	span[class*="icon-"] {
		font-size: $font-size;
	}
}

.a-link--inverted {
	@include extend-link-inverted();

	&.a-link--animated {

		@include extend-link-animated-inverted();
	}
}

