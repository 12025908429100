// =============================================================================
// :: Header
// =============================================================================

// =============================================================================
// :: Elements - title
// =============================================================================
.m-header__title {
	position: relative;

	display: flex;
	flex-direction: column;
	flex: 0 1 100%;

	@include respond-at(get-breakpoint("viewport-10")) {
		flex-direction: row;
	}
}

// =============================================================================
// :: Elements - number
// =============================================================================
.m-header__prefix {
	position: relative;

	width: 5.3rem;

	font-family: $font-family-beausans-semibold;
	font-weight: $font-weight-semi;

	@include respond-at(get-breakpoint("viewport-7")) {
		width: 6rem;
	}

	&::after {
		content: " ";

		position: absolute;
		top: 5rem;
		left: 0;

		width: 100%;
		height: 0.4rem;

		background-color: $primary-color;
	}
}

// =============================================================================
// :: Modifier - Displays
// =============================================================================
.m-header--order-column {

	// There is a default flex row on the .m-section__header so we have to add
	// this class as selector to force the flex column.
	.l-container--medium .m-header__title,
	.m-header__title {

		@include respond-at(get-breakpoint("viewport-10")) {
			flex-direction: column;
		}

		.m-header__prefix {
			position: relative;
			top: 0;
			left: 0;

			margin-right: 2rem;

			@include respond-at(get-breakpoint("viewport-10")) {
				margin-right: 0;
				margin-bottom: 1rem;
			}
		}
	}
}

// =============================================================================
// :: Modifier - Spacing
// =============================================================================
.m-header--spacing-small {

	@include respond-to(get-breakpoint("viewport-7")) {
		padding-bottom: 0;
	}
}
