// =============================================================================
// :: FOOTER
// =============================================================================
.o-footer {
	padding: 3rem;

	font-size: $font-size-1x-small;
	text-align: center;

	& .o-footer__item {
		color: $text-color-grey;
	}

	@include respond-to(get-breakpoint("viewport-4")) {
		margin-top: 0;
		padding: 3rem 0;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.o-footer__socials {
	width: 100%;
	margin: 4rem auto 3rem;
	padding-top: 2rem;

	border-top: $border-grey-large;
}

.o-footer__socials__button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4.1rem;
	height: 4.1rem;
	overflow: hidden;
	padding: 0;

	text-decoration: none;

	border-radius: $border-radius-round;
	background: $background-color-extra-light;

	transition: 0.6s;

	&::before {
		opacity: 0;

		transition: opacity 0.3s ease-in-out;
	}

	.o-footer__socials__text {

		&::before {
			margin-right: 0;

			font-size: $font-size-2x-large;

			color: $text-color-dark-brown;
		}
	}

	&:hover {
		background-color: $background-color-primary;

		transform: scale(0.9);

		&::before {
			opacity: 1;
		}

		.o-footer__socials__text::before {
			color: $text-color-white;
		}
	}
}

// =============================================================================
// :: Modifiers
// =============================================================================
.o-footer--brown {
	text-align: left;

	background-color: $background-color-brown;

	& .o-footer__item {
		color: $text-color-white;
	}
}
