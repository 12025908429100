// =============================================================================
// :: Base
// =============================================================================
html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-size: 62.5%;
}

body {
	font-family: $font-family-beausans-light;
	font-weight: $font-weight-light;
	font-size: $font-size;
	line-height: $line-height-1x-large;
}

// =============================================================================
// :: Headers
// =============================================================================
h1 {
	@include extend-h1-base();
}

h2 {
	@include extend-h2-base();
}

h3 {
	@include extend-h3-base();
}

h4 {
	@include extend-h4-base();
}

// =============================================================================
// :: Text
// =============================================================================
p,
strong {
	font-family: $font-family-beausans-light;
	font-weight: $font-weight-light;
	font-size: $font-size-1x-small;
	line-height: $line-height-1x-large;

	color: $text-color;

	@include respond-at(get-breakpoint("viewport-7")) {
		font-size: $font-size;
	}
}

strong {
	font-weight: $font-weight-bold;
}

// =============================================================================
// :: Link
// =============================================================================
a,
button {

	&:hover,
	&:active,
	&:focus {
		outline: none;
	}
}
