// =============================================================================
// :: Comparison table group
// =============================================================================
.m-comparison-tables-group {
	width: 100%;
	padding: 0 1rem;
}

// =============================================================================
// :: Elements
// =============================================================================
.m-comparison-tables-group__select {
	width: 100%;
	margin: 5rem auto;

	@include respond-at(get-breakpoint("viewport-4")) {
		width: 35rem;
	}
}
