// =============================================================================
// :: Sub Article
// =============================================================================
.m-sub-article {

	&:not(:last-child) {
		margin-bottom: 4rem;

		@include respond-at(get-breakpoint("viewport-7")) {
			margin-bottom: 8rem;
		}
	}
}

// =============================================================================
// :: Elements - title
// =============================================================================
.m-sub-article__title {

	.m-header__prefix {
		width: 4.4rem;
		margin-bottom: 1;

		@include respond-at(get-breakpoint("viewport-7")) {
			width: 6.2rem;
			margin-bottom: 1rem;
		}

		@include respond-at(get-breakpoint("viewport-10")) {
			width: auto;
			margin-bottom: 0;
		}

		&::after {
			top: 4rem;

			@include respond-at(get-breakpoint("viewport-7")) {
				top: 5rem;
			}
		}
	}
}
