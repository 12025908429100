// =============================================================================
// :: Content
// =============================================================================

// =============================================================================
// :: Elements - subtitle
// =============================================================================
.m-content__subtitle {
	margin-bottom: 2rem;

	font-family: $font-family-beausans-regular;
	font-weight: $font-weight-normal;
}

.m-content__intro {
	margin-bottom: 5rem;
}

.m-content__item {

	&:not(:last-child) {
		margin-bottom: 2rem;
	}

	&:only-child {
		margin-bottom: 0;
	}

	@include respond-at(get-breakpoint("viewport-7")) {

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

.m-content__section {

	&:not(:last-child) {
		margin-bottom: 6rem;
	}

	@include respond-at(get-breakpoint("viewport-7")) {

		&:not(:last-child) {
			margin-bottom: 6rem;
		}
	}
}
