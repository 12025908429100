/* stylelint-disable */

// =============================================================================
// :: Visibility
// =============================================================================
/**
 * These classes determine based on the breakpoints set up in the
 * src/scss/general/settings/_media.scss file when to hide or when to display
 * an element.
 */

/**
 * Simple hide the element
 */
.u-hide {
	display: none !important;
}

/**
 * Hide the element on mobile
 */
.u-mobile-hide {

	@include respond-to(get-breakpoint("viewport-4")) {
		display: none !important;
	}
}

/**
 * Show the element on mobile
 */
.u-mobile-show {

	@include respond-at(get-breakpoint("viewport-4")) {
		display: none !important;
	}
}

/**
 * Hide the element on tablet
 */
.u-tablet-hide {

	@include respond-to(get-breakpoint("viewport-7")) {
		display: none !important;
	}
}

/**
 * Show the element on tablet
 */
.u-tablet-show {

	@include respond-at(get-breakpoint("viewport-7")) {
		display: none !important;
	}
}

/**
 * Hide the element on desktop
 */
.u-desktop-hide {

	@include respond-to(get-breakpoint("viewport-10")) {
		display: none !important;
	}
}

/**
 * Show the element on desktop
 */
.u-desktop-show {

	@include respond-at(get-breakpoint("viewport-10")) {
		display: none !important;
	}
}

/**
 * Hide the element on desktop
 */
.u-desktop-large-hide {

	@include respond-to(get-breakpoint("viewport-12")) {
		display: none !important;
	}
}

/**
 * Show the element on desktop
 */
.u-desktop-large-show {

	@include respond-at(get-breakpoint("viewport-12")) {
		display: none !important;
	}
}

/* stylelint-enable */
