// =============================================================================
// :: Source
// =============================================================================
.a-source {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	width: 100%;
}

// =============================================================================
// :: Elements - text
// =============================================================================
.a-source__text {
	color: $text-color-primary;
}

// =============================================================================
// :: Modifiers - spacing
// =============================================================================
.a-source--no-spacing {
	padding-top: 0;
}
