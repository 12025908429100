// =============================================================================
// :: Book
// =============================================================================
.m-book {
	display: flex;
	flex-direction: column;
}

// =============================================================================
// :: Elements
// =============================================================================
.m-book__container {
	position: relative;

	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	margin-bottom: 2rem;

	transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.32, 1.27);

	&:hover {
		transform: translateY(-0.6rem);

		.m-book__title {
			color: $text-color-primary;
		}
	}
}

.m-book__title {
	font-size: $font-size-4x-large;
	line-height: $line-height-2x-large;
	font-weight: $font-weight-bold;

	transition: color 0.2s ease-in-out;
}

.m-book__image-container {
	display: flex;
	flex-shrink: 0;
	order: -2;
	width: 100%;
	height: auto;
	margin-bottom: 2rem;

	background-color: $background-color-primary;
	box-shadow: $box-shadow-light;
}

.m-book__image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.m-book__subtitle {
	order: -1;

	font-weight: $font-weight-bold;

	color: $primary-color;
}

.m-book__button {
	font-weight: $font-weight-semi;
	line-height: $line-height-1x-large;

	&::before {
		font-size: $font-size-1x-large;
		line-height: $line-height-1x-large;
	}
}

.m-book__published {
	font-weight: $font-weight-normal;
	font-size: $font-size-1x-small;

	color: $text-color-grey-light;
}

.m-book__link {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	width: 100%;
	height: 100%;
}
