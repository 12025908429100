// ============================================================================
// :: H1
// ============================================================================
@mixin extend-h1-base {
	font-family: $font-family-beausans-thin;
	font-weight: $font-weight-thin;
	font-size: $font-size-5x-large;
	line-height: $line-height-3x-large;

	color: $text-color-dark-brown;

	@include respond-at(get-breakpoint("viewport-7")) {
		font-size: $font-size-6x-large;
		line-height: $line-height-4x-large;
	}
}

// ============================================================================
// :: H2
// ============================================================================
@mixin extend-h2-base {
	font-family: $font-family-beausans-thin;
	font-weight: $font-weight-thin;
	font-size: $font-size-5x-large;
	line-height: $line-height-3x-large;

	color: $text-color-dark-brown;

	@include respond-at(get-breakpoint("viewport-7")) {
		font-size: $font-size-6x-large;
		line-height: $line-height-4x-large;
	}
}

// ============================================================================
// :: H3
// ============================================================================
@mixin extend-h3-base {
	font-family: $font-family-beausans-thin;
	font-weight: $font-weight-thin;
	font-size: $font-size-4x-large;
	line-height: $line-height-2x-large;

	color: $text-color-dark-brown;

	@include respond-at(get-breakpoint("viewport-7")) {
		font-size: $font-size-4x-large;
		line-height: $line-height-3x-large;
	}
}

// ============================================================================
// :: H4
// ============================================================================
@mixin extend-h4-base {
	font-family: $font-family-beausans-light;
	font-size: $font-size-3x-large;
	line-height: $line-height-2x-large;
	font-weight: $font-weight-normal;

	color: $text-color;
}
