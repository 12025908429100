// =============================================================================
// :: Timeline
// =============================================================================
.m-timeline {
	position: relative;

	&::before {
		content: "";

		position: absolute;
		top: 0.5rem;
		left: 1.1rem;

		width: 0.1rem;
		height: 100%;
		margin-left: -0.1rem;

		background: $background-color-light-brown;
	}
}

// =============================================================================
// :: Elements - block
// =============================================================================
.m-timeline__block {
	display: flex;
	justify-content: space-between;

	color: $text-color-dark-brown;

	&:last-child {

		.m-timeline__block-content {
			padding-bottom: 0;
		}
	}
}

// =============================================================================
// :: Elements - block - content
// =============================================================================
.m-timeline__block-content {
	width: 95%;
	padding: 0 0 4rem 4.5rem;
}

// =============================================================================
// :: Elements - block - marker
// =============================================================================
.m-timeline__marker-item-outer {
	position: relative;

	width: 2.2rem;
	height: 2.2rem;

	border-radius: 50%;
	background: $background-color-primary;
}

.m-timeline__marker--item-inner {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: $z-index-base;

	width: 1.8rem;
	height: 1.8rem;
	margin-left: -0.9rem;

	border-radius: 50%;
	border: 0.22rem solid $background-color-white;
	background: $background-color-primary;

	transform: translateY(-50%);
}

// =============================================================================
// :: Elements - part - marker
// =============================================================================
.m-timeline__part-content {

	&:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

.m-timeline__part-item {

	&:not(:last-child) {
		margin-bottom: 0.5rem;
	}
}
