// =============================================================================
// :: Header
// =============================================================================
/**
 * The header is placed on top and contains navigation,
 * logo and more actions to make the user navigate through the one-pager.
 */

// =============================================================================
// :: Settings
// =============================================================================
$logo-width: 12.9rem;
$logo-width-mobile: 10rem;
$nav-open-duration: 0.5s;
$nav-close-duration: 0.5s;

// =============================================================================
// :: Root
// =============================================================================
.o-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-highest + 1;

	display: block;
	width: 100%;
	padding: 1.5rem 0;

	background-color: $background-color-white;
	box-shadow: $box-shadow;

	@include respond-at(get-breakpoint("viewport-4")) {
		padding: 1rem 0;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.o-header__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.o-header__logo {
	z-index: $z-index-higher + 1;

	display: flex;
	justify-content: center;
	align-items: center;

	width: $logo-width;
	padding: 0.5rem 0 1.5rem;
}

.o-header__logo-image {
	display: flex;
	align-items: center;
	max-width: 100%;
}

.o-header__action {
	z-index: $z-index-higher + 1;

	display: flex;
	justify-content: flex-start;
	align-items: center;

	margin-right: 3rem;
	margin-left: auto;

	.o-header__action-item {

		&:not(:last-child) {
			margin-right: 2rem;
		}
	}
}

.o-header__hamburger {
	position: relative;
	z-index: $z-index-higher + 1;
}
