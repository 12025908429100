// =============================================================================
// :: Legend
// =============================================================================

// =============================================================================
// :: Elements
// =============================================================================
.m-legend__term {
	width: 3rem;

	&.m-legend__succes {
		color: $text-color-primary;
	}

	&.m-legend__error {
		color: $text-error;
	}
}

.m-legend__item {
	display: flex;
	margin: 0;

	&:not(:first-of-type) {
		margin-top: 2rem;
	}

	&:not(:last-of-type) {
		border-bottom: $border-grey;
	}
}
