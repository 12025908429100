// =============================================================================
// =============================================================================
// :: Settings
// =============================================================================
$visual-width: 8rem;

// =============================================================================
// :: Trivia
// =============================================================================

// =============================================================================
// :: Elements - visual
// =============================================================================
.m-trivia__visual {
	width: $visual-width;
	margin: 0 0 4rem;

	@include respond-at(get-breakpoint("viewport-7")) {
		margin: 0.5rem 4rem 0 0;
	}
}

// =============================================================================
// :: Elements - content
// =============================================================================
.m-trivia__content {
	width: 100%;
}

// =============================================================================
// :: Elements - item
// =============================================================================
.m-trivia__item {
	color: $text-color-white;

	& strong {
		color: inherit;
	}
}

// =============================================================================
// :: Elements - title
// =============================================================================
.m-trivia__item-title,
.m-trivia__title {
	font-weight: $font-weight-bold;

	color: $text-color-white;
}
