// =============================================================================
// =============================================================================
// :: Settings
// =============================================================================
$visual-width: 8rem;

// =============================================================================
// :: Separator
// =============================================================================
.m-separator {
	height: 20rem;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@include respond-at(get-breakpoint("viewport-4")) {
		height: 30rem;
	}

	@include respond-at(get-breakpoint("viewport-7")) {
		height: 45rem;
	}
}
// =============================================================================
// :: Modifiers - Background image
// =============================================================================
.m-separator--background-image-1 {
	background-image: url("/assets/images/separators/foggy-forest.jpg");
}

.m-separator--background-image-2 {
	background-image: url("/assets/images/separators/truck.jpg");
}

.m-separator--background-image-3 {
	background-image: url("/assets/images/separators/lichte-vracht.jpg");
}

.m-separator--background-image-4 {
	background-image: url("/assets/images/separators/rails.jpg");
}

.m-separator--background-image-5 {
	background-image: url("/assets/images/ebook-3/Bebat-fotoshoot-sortbat.jpg");
}

.m-separator--background-image-6 {
	background-image: url("/assets/images/ebook-3/car-sun-horizon.jpg");
}
