// =============================================================================
// :: Highlight
// =============================================================================
.m-highlight {
	z-index: $z-index-low;

	padding: 10rem 0;

	background: $background-color-primary;
}

// =============================================================================
// :: Modifiers - Backgrounds
// =============================================================================
.m-highlight--background {
	position: relative;

	background: transparent;

	&::before,
	&::after {
		content: " ";

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}

	&::before {
		z-index: $z-index-lowest;

		background: $background-color-primary;
	}

	&::after {

		@include respond-at(get-breakpoint("viewport-7")) {
			z-index: $z-index-lowest;

			background-image:
				linear-gradient(to right, $background-color-primary 50%, rgba($background-color-primary, 0.4) 100%),
				url("../assets/images/highlights/background-1.jpg");
			background-color: $background-color-primary;
			background-position: top;
			background-size: cover;

			opacity: 0.35;
		}
	}
}

.m-highlight--background-image-1 {

	&::after {

		@include respond-at(get-breakpoint("viewport-7")) {
			background-image:
				linear-gradient(to right, $background-color-primary 50%, rgba($background-color-primary, 0.4) 100%),
				url("../assets/images/highlights/background-1.jpg");
		}
	}
}

.m-highlight--background-image-2 {

	&::after {

		@include respond-at(get-breakpoint("viewport-7")) {
			background-image:
				linear-gradient(to left, $background-color-primary 50%, rgba($background-color-primary, 0.4) 100%),
				url("../assets/images/highlights/background-2.jpg");
		}
	}
}

.m-highlight--background-image-3 {

	&::after {

		@include respond-at(get-breakpoint("viewport-7")) {
			background-image:
				linear-gradient(to left, $background-color-primary 20%, rgba($background-color-primary, 0.2) 100%),
				url("../assets/images/ebook-3/background-3.jpg");
			background-size: 100%, 80%;
			background-repeat: no-repeat;
			background-position: left;
		}
	}
}

.m-highlight--background-image-4 {

	&::after {

		@include respond-at(get-breakpoint("viewport-7")) {
			background-image:
				linear-gradient(to right, $background-color-primary 50%, rgba($background-color-primary, 0.4) 100%),
				url("../assets/images/ebook-3/background-4.jpg");
			background-repeat: no-repeat;
			background-position: center right;
			background-size: 100%, contain;
		}
	}
}
