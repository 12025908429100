// =============================================================================
// :: List
// =============================================================================
.m-list {
	padding: 0;
}
// =============================================================================
// :: Element - Item
// =============================================================================
.m-list__item {
	color: $text-color-dark-brown;

	&:not(:last-child) {
		margin-bottom: 0.8rem;
	}

	&:last-child {
		margin-bottom: 0.4rem;
	}

	&.a-link {
		vertical-align: text-top;
	}
}

// =============================================================================
// :: Element - Title
// =============================================================================
.m-list__item-title {
	color: $text-color-dark-brown;
	font-weight: $font-weight-normal;
}

// =============================================================================
// :: Element - Description
// =============================================================================
.m-list__item-description {
	color: $text-color;
}

// =============================================================================
// :: Modifiers - Colors
// =============================================================================
.m-list__item--green {
	color: $text-color-primary;
}

// =============================================================================
// :: Modifiers - Colors
// =============================================================================
.m-list__item--brown {
	color: $text-color;
}

// =============================================================================
// :: Modifiers - Colors
// =============================================================================
.m-list__item--white {
	padding-left: 5rem;

	color: $text-color-white;
}

// =============================================================================
// :: Modifiers - Decoration
// =============================================================================
.m-list--no-type {
	padding-left: 0;

	list-style-type: none;

	.m-list__item::before {
		display: none;
	}
}

// =============================================================================
// :: Modifiers - Decoration
// =============================================================================
.m-list--with-description {

	.m-list__item {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		margin-bottom: 2rem;
	}

	.m-list__item-icon {
		display: flex;

		margin-right: 1.5rem;
		padding-top: 0.5rem;

		font-size: $font-size-2x-large;

		color: $text-color-primary;
	}
}

.m-list--icon-before {

	.m-list__item {
		display: flex;
		align-items: flex-start;

		color: $text-color;

		[class*="icon"] {
			@include extend-list-icon-arrow();
		}
	}
}

// =============================================================================
// :: Modifiers - With links
// =============================================================================
.m-list--with-links {

	.a-link {
		vertical-align: text-top;
	}
}
