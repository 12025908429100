/* stylelint-disable selector-no-qualifying-type */

// =============================================================================
// :: Settings
// =============================================================================
// As the design suggests this is the appropriate width of the header title
// prefix.
$header-title-prefix-width: 9rem;
$header-title-prefix-width-small: 6rem;

$header-breakpoint-large: calc(#{$container-width-base-breakpoint} + (#{$header-title-prefix-width} * 2));

$indentation-medium: calc((100vw - #{$container-width-base}) * 0.5);

// =============================================================================
// :: Section
// =============================================================================
.m-section {
	padding: 6rem 0;

	@include respond-at(get-breakpoint("viewport-4")) {
		padding: 10rem 0;
	}

	.l-container--medium {

		.m-header__prefix {
			margin-right: 2rem;

			@include respond-at($header-breakpoint-large) {
				position: absolute;
				top: 0;
				left: calc(#{$header-title-prefix-width} * -1);

				margin-right: 0;
				margin-bottom: 0;
			}
		}

		.m-sub-article__title {

			.m-header__prefix {

				@include respond-at($header-breakpoint-large) {
					left: calc(#{$header-title-prefix-width-small} * -1);
				}
			}
		}
	}
}

// =============================================================================
// :: Elements - header
// =============================================================================
.m-section__header {
	margin-bottom: 2rem;

	@include respond-at(get-breakpoint("viewport-7")) {
		margin-bottom: 4rem;
	}
}

// =============================================================================
// :: Elements - toc
// =============================================================================
.m-section__toc {
	position: relative;
}

.m-section__toc-container {
	// Tell the browser beforehand this property will be animated to avoid jagged
	// animation on retina screens when scrolling
	will-change: transform;

	// In order to overwrite the sticky JavaScript component we must use !important
	@include respond-to(get-breakpoint("viewport-10")) {
		/* stylelint-disable */
		transform: translateY(0) !important;
		/* stylelint-enable */
	}
}

// =============================================================================
// :: Elements - articles
// =============================================================================
.m-section__articles {

	.m-section {
		margin-bottom: 4rem;

		@include respond-at(get-breakpoint("viewport-7")) {
			margin-bottom: 8rem;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

// =============================================================================
// :: Elements - content
// =============================================================================
.m-section__content {

	&:not(:last-child) {

		@include respond-to(get-breakpoint("viewport-10")) {
			margin-bottom: 4rem;
		}
	}

	.m-section__header {
		margin-bottom: 1.5rem;

		@include respond-at(get-breakpoint("viewport-7")) {
			margin-bottom: 4rem;
		}
	}
}

.m-section__image {
	position: relative;

	width: 100%;
	height: 100%;
	min-height: 80vh;

	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.m-section__image-quote {
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	padding: 3rem 0;

	background-color: $background-color-primary;
}

// =============================================================================
// :: Modifiers - align center
// =============================================================================
.m-section--centered {

	.l-grid {
		align-items: center;
	}
}

.m-section__header--centered {
	display: flex;
	justify-content: center;
}

// =============================================================================
// :: Modifiers - background colors
// =============================================================================
.m-section--grey {
	background-color: $background-color-grey-lightest;
}

.m-section--green {
	background-color: $background-color-primary;
}

.m-section__image-quote--dark {
	background-color: $background-color-brown;
}

// =============================================================================
// :: Modifiers - split
// =============================================================================
.m-section--split-right,
.m-section--split-left {
	vertical-align: center;
	min-height: 80vh;
	padding-top: 0;
	padding-bottom: 0;

	.m-section__content {
		padding: 6rem 0;

		@include respond-at(get-breakpoint("viewport-4")) {
			padding: 10rem 0;
		}
	}
}

.m-section--split-left {

	.m-section__content {

		@each $breakpoint, $params in trim-to($breakpoints, "viewport-10") {
			$width: map-get($params, width);
			$spacing: map-get($params, spacing);

			@include respond-at(px($width)) {
				padding-right: #{$spacing / 2};
			}
		}
	}

	.m-section__image-quote {

		@each $breakpoint, $params in trim-to($breakpoints, "viewport-10") {
			$width: map-get($params, width);
			$spacing: map-get($params, spacing);

			@include respond-at(px($width)) {
				padding-left: #{$spacing / 2};
			}
		}
	}
}

.m-section--split-right {

	.m-section__content {

		@each $breakpoint, $params in trim-to($breakpoints, "viewport-10") {
			$width: map-get($params, width);
			$spacing: map-get($params, spacing);

			@include respond-at(px($width)) {
				padding-left: #{$spacing / 2};
			}
		}
	}

	.m-section__image-quote {

		@each $breakpoint, $params in trim-to($breakpoints, "viewport-10") {
			$width: map-get($params, width);
			$spacing: map-get($params, spacing);

			@include respond-at(px($width)) {
				padding-right: #{$spacing / 2};
			}
		}
	}
}

// =============================================================================
// :: Modifiers - sub
// =============================================================================
.m-section--sub {
	margin-top: -10rem;
	padding-top: 10rem;
	padding-bottom: 0;

	h3 {

		&.m-header__title {
			font-family: $font-family-beausans-thin;
			font-weight: $font-weight-thin;
			font-size: $font-size-4x-large;
			line-height: $line-height-2x-large;

			color: $text-color-dark-brown;

			@include respond-at(get-breakpoint("viewport-7")) {
				font-size: $font-size-4x-large;
				line-height: $line-height-3x-large;
			}
		}
	}

	.m-content {
		margin: 6rem 0;

		&:first-child {
			margin-top: 0;
		}
	}
}

// =============================================================================
// :: Modifiers - spacing
// =============================================================================
.m-section--no-vertical-spacing {
	padding-top: 0;
	padding-bottom: 0;
}

.m-section--no-vertical-spacing-top {
	padding-top: 0;
}

.m-section--no-vertical-spacing-bottom {
	padding-bottom: 0;
}

.m-section--sub-no-vertical-spacing-top {
	/* stylelint-disable */
	margin-top: 0 !important;
	/* stylelint-enable */
}

.m-section__content .m-section__header--spacing-small,
.m-section__header--spacing-small {
	margin-bottom: 1.5rem;

	@include respond-at(get-breakpoint("viewport-7")) {
		margin-bottom: 2rem;
	}
}
