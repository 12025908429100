// ============================================================================
// :: Font families
// ============================================================================
@include get-font("icons", "/assets/fonts/icons/icons");
@include get-font("Beausans Thin", "/assets/fonts/beausans/PFBeauSansPro-Thin", 100, normal);
@include get-font("Beausans Light", "/assets/fonts/beausans/PFBeauSansPro-Light", 200, normal);
@include get-font("Beausans Regular", "/assets/fonts/beausans/PFBeauSansPro-Regular", 400, normal);
@include get-font("Beausans Semibold", "/assets/fonts/beausans/PFBeauSansPro-SemiBold", 600, normal);
@include get-font("Beausans Bold", "/assets/fonts/beausans/PFBeauSansPro-Bold", 700, normal);
@include get-font("Roboto", "/assets/fonts/roboto/Roboto-Light", 300);
@include get-font("Roboto", "/assets/fonts/roboto/Roboto-Bold", 700);

$font-family-beausans-thin: "Beausans Thin";
$font-family-beausans-light: "Beausans Light";
$font-family-beausans-regular: "Beausans Regular";
$font-family-beausans-semibold: "Beausans Semibold";
$font-family-beausans-bold: "Beausans Bold";

$font-family-roboto: "Roboto";

// =============================================================================
// :: Font sizes
// =============================================================================
$font-size-6x-large: 4.8rem; // h1 on mobile
$font-size-5x-large: 4.2rem; // h1, h2
$font-size-4x-large: 3rem; // h3
$font-size-3x-large: 2.6rem; // h4
$font-size-2x-large: 2rem; // h5
$font-size-1x-large: 1.6rem; // link
$font-size: 1.7rem; // p
$font-size-1x-small: 1.6rem; // h6, p on mobile
$font-size-2x-small: 1.4rem; // h6, p on mobile

// ============================================================================
// :: Line heights
// ============================================================================
$line-height-4x-large: 5.7rem; // h1
$line-height-3x-large: 5.2rem; // h1
$line-height-2x-large: 3.8rem; // h2
$line-height-1x-large: 3.1rem; // h4, p
$line-height: 1.6rem;

// ============================================================================
// :: Font weights
// ============================================================================
$font-weight-bold: 700;
$font-weight-semi: 600;
$font-weight-normal: 400;
$font-weight-light: 200;
$font-weight-thin: 100;
