// =============================================================================
// :: Ebook 1
// ============================================================================
/*
 * Ebook 2 has more sub section per chapter and thus more headings. Therefore,
 * Ebook 1 skips the styling of H3 en from then on, uses the corresponding
 * heading style + 1.
 *
 * --> Ex. H3 in Ebook 1 has the same styling of H4 in ebook 2
*/
.p-ebook1 {

	h3 {
		font-family: $font-family-beausans-thin;
		font-weight: $font-weight-thin;
		font-size: $font-size-4x-large;
		line-height: $line-height-2x-large;

		color: $text-color-dark-brown;

		@include respond-at(get-breakpoint("viewport-7")) {
			font-size: $font-size-4x-large;
			line-height: $line-height-3x-large;
		}
	}

	h4 {
		font-family: $font-family-beausans-light;
		font-size: $font-size-3x-large;
		line-height: $line-height-2x-large;
	}
}
