// =============================================================================
// :: Settings
// =============================================================================
$menu-item-gutter: 5rem;

// =============================================================================
// :: Nav
// =============================================================================
.o-nav {
	position: fixed;
	top: 7rem;
	left: 0;
	z-index: $z-index-highest;

	width: 100vw;
	height: calc(100vh - 7rem);
	overflow: auto;

	background-color: $background-color-white;

	transform: translateX(100%) translateY(0);

	transition: transform 1.3s cubic-bezier(0.16, 1.08, 0.38, 0.98);

	@include respond-at(get-breakpoint("viewport-7")) {
		transform: translateX(0) translateY(-100%);
	}

	@include respond-to(get-breakpoint("viewport-7")) {
		padding-top: 4rem;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.o-nav__container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;

	height: 100%;

	@include respond-at(get-breakpoint("viewport-7")) {
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
}

.o-nav__list {
	padding: 0;

	opacity: 0;

	list-style-type: none;

	transition: opacity 0.5s ease-in-out;
	transition-delay: 1000;
}

.l-grid .o-nav__item,
.l-grid--with-spacing .o-nav__item,
.o-nav__item {
	position: relative;

	padding: 3rem;

	box-shadow: $box-shadow-large;

	@include respond-to(get-breakpoint("viewport-7")) {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		width: calc(100% - 1rem);
		margin: 0.5rem;
		padding: 2rem;
	}

	&::before {
		content: "";

		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 1.4rem;

		background: $gradient-primary;

		transform: scaleY(0);
		transform-origin: center bottom;

		transition: transform 0.3s ease-in-out;
	}

	&:hover {

		&::before {
			transform: scaleY(1);
		}
	}
}

.o-nav__link {
	position: absolute;
	top: 0;
	left: 0;
	z-index: $z-index-highest;

	display: block;
	width: 100%;
	height: 100%;

	cursor: pointer;
}

.o-nav__text {
	font-size: $font-size-2x-large;
	line-height: $line-height-1x-large;
	text-decoration: none;

	@include respond-at(get-breakpoint("viewport-7")) {
		margin-top: 2rem;

		text-align: center;
	}

	@include respond-at(get-breakpoint("viewport-10")) {
		color: $text-color;

		transition: color 0.15s ease-in-out;
	}
}

.o-nav__visual {
	width: 60%;
	max-width: 4rem;
	margin-right: 2rem;

	@include respond-at(get-breakpoint("viewport-7")) {
		width: 40%;
		max-width: none;
		margin: 0 auto;
	}
}

// =============================================================================
// :: States
// =============================================================================
.o-nav {

	&.is-open {
		transform: translateY(0);

		.o-nav__list {
			opacity: 1;
		}
	}
}
