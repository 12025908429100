// =============================================================================
// :: Overlay
// =============================================================================
.o-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-highest;

	width: 100%;
	height: 100%;

	opacity: 0;

	transition: opacity 0s ease-in-out 0.5s;

	pointer-events: none;

	& > .m-section {
		padding-bottom: 0;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.o-overlay__background {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0;
	background-color: rgba($background-color-black, 0.3);

	transition: opacity 0.5s ease-in-out;
}

.o-overlay__content {
	position: relative;
	z-index: $z-index-higher;

	display: flex;
	flex-direction: column;
	max-width: calc(100% - #{$container-spacing-mobile});
	height: auto;
	margin: 0 auto;
	padding: 1.5rem 0;

	opacity: 0;

	transition:
		transform 0.5s ease-in-out,
		opacity 0.5s ease-in-out;

	@include respond-at(get-breakpoint("viewport-7")) {
		padding: 3rem 0;
	}

	@include respond-at(get-breakpoint("viewport-12")) {
		max-width: $container-width-large;

		transform: translateY(2rem);
	}

	.l-split {
		width: 100%;
		margin: 0;
	}
}

.o-overlay__content-container {
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	overflow: auto;

	background-color: $background-color-white;
	box-shadow: $box-shadow;

	.m-section {
		width: 100%;
	}
}

.o-overlay__header {
	z-index: $z-index-higher;

	padding-top: 3rem;
	padding-bottom: 3rem;

	background-color: $background-color-primary;
}

.o-overlay__header-container {
	position: relative;

	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: calc(100% - #{$container-spacing-mobile});
	margin: 0 auto;

	@include respond-at(get-breakpoint("viewport-10")) {
		max-width: $container-width-base;
	}
}

.o-overlay__header-title {
	display: flex;
	align-items: center;

	font-size: $font-size-4x-large;

	color: $text-color-white;
}

.o-overlay__header-title-visual {
	width: auto;
	margin-right: 2rem;

	.a-image__visual,
	.a-image {
		width: auto;
		height: 9rem;
	}
}

.o-overlay__close {
	background-color: transparent;
	border: none;

	cursor: pointer;

	&::before {
		font-size: 1.4rem;

		color: $text-color-white;
	}
}

// =============================================================================
// :: States
// =============================================================================
.o-overlay {

	&.is-open {
		opacity: 1;

		transition: opacity 0s ease-in-out;

		pointer-events: auto;

		.o-overlay__background {
			opacity: 1;
		}

		.o-overlay__content {
			opacity: 1;

			transform: translateY(0);
		}
	}

	.o-overlay__background {

		&.is-hidden {
			opacity: 0;
		}
	}
}

// .o-overlay--center {

// 	&.is-open {

// 		.o-overlay__content {

// 			opacity: 1;
// 		}
// 	}
// }
