// =============================================================================
// :: Lightbox figure
// =============================================================================
.a-lightbox-figure {
	display: flex;
	justify-content: center;

	margin: 0;
	padding: 0;
}
