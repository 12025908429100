// =============================================================================
// :: Button
// =============================================================================
.a-button {
	display: inline-block;
	padding: 0.5rem 2rem;

	font-family: $font-family-beausans-bold;
	font-size: $font-size;
	text-align: left;
	text-decoration: none;

	color: $text-color-white;
	background: $gradient-primary;
	border: none;
	border-radius: $border-radius;

	appearance: none;
	background-size: 200% auto;
	background-position: 50% 100%;

	transition:
		background-position 0.2s ease-in-out,
		background-size 0.2s ease-in-out,
		color 0.2s ease-in-out;

	cursor: pointer;

	@include respond-at(get-breakpoint("viewport-4")) {
		padding: 0.6rem 3rem;

		font-size: $font-size-2x-large;
	}

	&[class*="icon"] {
		display: inline-flex;
		justify-content: center;
		align-items: center;

		&::before {
			font-size: $font-size-1x-small;
		}
	}

	[class*="icon"] {
		opacity: 0.5;

		transition: opacity 0.3s ease-in-out;
	}

	&:hover:not([disabled]) {
		background-size: 300% auto;
		background-position: 100% 200%;
	}

	&:hover {

		[class*="icon"] {
			opacity: 1;
		}
	}
}

// =============================================================================
// :: Modifiers
// =============================================================================
.a-button--inverted {
	color: $text-color-primary;
	background: $background-color-white;
}

.a-button--icon {

	.a-button__text {
		margin-left: 1rem;
	}
}

.a-button--link {

	&[class*="icon"] {
		padding: 1rem 1.5rem;

		&::before {
			font-size: 2.4rem;
		}
	}
}
