/* stylelint-disable selector-max-type */
/* stylelint-disable function-calc-no-invalid */
// =============================================================================
// :: Settings
// =============================================================================
$quote-img-size: 7.2rem;
$quote-img-spacing: 4rem;

$quote-img-size-small: 4.6rem;
$quote-img-spacing-small: 3rem;

// =============================================================================
// :: WYSIWYG
// =============================================================================
// Styles the markdown input coming from the PEOeditor
.s-wysiwyg {

	h3 {

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	h4 {

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	p {

		&:not(:last-child) {
			margin-bottom: 2rem;
		}

		&:only-child {
			margin-bottom: 0;
		}

		@include respond-at(get-breakpoint("viewport-7")) {

			&:not(:last-child) {
				margin-bottom: 2rem;
			}
		}
	}

	ul {
		padding-left: 0;

		list-style-type: none;
	}

	li {
		@extend %__icon-arrow-right;

		display: flex;
		align-items: flex-start;

		color: $text-color;

		&::before {
			@include extend-list-icon-arrow();
		}
	}

	a:not(.a-lightbox-figure__link) {
		@include extend-link();
		@include extend-link-animated();

		font-size: $font-size;
		line-height: $line-height;
	}

	blockquote {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		margin-right: 0;
		margin-left: 0;

		&:before { /* stylelint-disable-line */
			@extend %__icon-quote;

			margin-bottom: 3rem;

			font-size: 8rem;

			color: $primary-color;
		}

		@include respond-at(get-breakpoint("viewport-7")) {
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;

			&:before { /* stylelint-disable-line */
				margin-right: $quote-img-spacing;
				margin-bottom: 0;

				font-size: $quote-img-size;
			}
		}

		p {
			font-style: italic;
		}

		/* The author of the quote comes right after the blockquote
		and is placed in between em-tags. */
		+ p {

			em {
				font-style: normal;

				a {
					font-size: inherit;
				}

				@include respond-at(get-breakpoint("viewport-7")) {
					display: block;
					margin-left: calc(#{$quote-img-size} + #{$quote-img-spacing});
				}
			}
		}
	}

	.m-trivia-block:not(:last-child) {
		margin-bottom: 2rem;
	}
}

// =============================================================================
// :: MODIFIER
// =============================================================================
.s-wysiwyg--inverted {

	h3,
	h4,
	h5,
	p,
	span,
	blockquote,
	em,
	li {
		color: $text-color-white;
	}

	blockquote {

		&::before {
			color: white;
		}
	}

	a,
	a:not(.a-lightbox-figure__link) {
		@include extend-link-inverted();
		@include extend-link-animated-inverted();
	}
}

.s-wysiwyg--quote-small {

	blockquote {

		&:before { /* stylelint-disable-line */
			@extend %__icon-quote;

			margin-bottom: $quote-img-spacing-small;

			font-size: $quote-img-size-small;
		}

		@include respond-at(get-breakpoint("viewport-7")) {

			&:before { /* stylelint-disable-line */
				margin-top: 0.2rem; /* Fix unwanted offset by content/text line-height */
				margin-right: $quote-img-spacing-small;
				margin-bottom: 0;
			}
		}

		/* The styling of the actual quote */
		p {
			/* A full width to prevent small text to be centered on smaller screens */
			width: 100%;

			font-size: 2.2rem;
		}

		/* The author of the quote comes right after the blockquote
		and is placed in between em-tags. */
		+ p {

			em {

				@include respond-at(get-breakpoint("viewport-7")) {
					margin-left: calc(#{$quote-img-size-small} + #{$quote-img-spacing-small});
				}
			}
		}
	}
}
